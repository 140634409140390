import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { Code, Line, Var } from "@molnx-sites/common/components"

import { DocLink, Page, PageHeader, PageNavigation, PageSection, SEO } from "src/components"

export default function DispatchPage() {
    const images = useStaticQuery(graphql`
        query {
          dashboard: file(relativePath: { eq: "dispatch/dashboard.png" }) {
            childImageSharp {
              fixed(width: 600) {
                ...GatsbyImageSharpFixed
              }
            }
          },
          
          domainVerification: file(relativePath: { eq: "dispatch/domain-verification.png" }) {
            childImageSharp {
              fixed(width: 600) {
                ...GatsbyImageSharpFixed
              }
            }
          },
          
          messageDelivery: file(relativePath: { eq: "dispatch/message-delivery.png" }) {
            childImageSharp {
              fixed(width: 600) {
                ...GatsbyImageSharpFixed
              }
            }
          },
          
          scheduledMessages: file(relativePath: { eq: "dispatch/scheduled-messages.png" }) {
            childImageSharp {
              fixed(width: 500) {
                ...GatsbyImageSharpFixed
              }
            }
          },
          
          targetList: file(relativePath: { eq: "dispatch/target-list.png" }) {
            childImageSharp {
              fixed(width: 800) {
                ...GatsbyImageSharpFixed
              },
            }
          }
        }
      `)

    return (
        <Page>
            <SEO title="Dispatch"/>

            <PageHeader large header="Dispatch">
                <p>
                    Easily schedule application messages to be delivered at a specific time in the future. Deliver
                    messages to your custom <em>Webhooks</em>, <em>Amazon SQS</em> and <em>SNS</em>.
                </p>
            </PageHeader>

            <PageNavigation>
                <ul>
                    <li><a href="#introduction">Introduction</a></li>
                    <li><a href="#use-cases">Use Cases</a></li>
                    <li><a href="#pricing">Pricing</a></li>
                    <li><a href="#documentation">Documentation</a></li>
                </ul>
            </PageNavigation>

            <PageSection>
                <h1><a id="introduction">Introduction</a></h1>

                <p>
                    Dispatch is a service that allows you to schedule a message to be delivered at a specific time in
                    the future. It enables you to easily power your <a href="#use-cases">asynchronous processes</a>
                    {" "}
                    without investing in a more heavy-weight workflow engine.
                </p>

                <p>
                    Dispatch currently supports delivering messages to custom webhooks, as well as integrating with
                    cloud messaging systems you might already be using.
                </p>

                <h2>Supported delivery targets</h2>
                <ul>
                    <li><DocLink to="/dispatch/targets/webhooks">Webhooks</DocLink></li>
                    <li><DocLink to="/dispatch/targets/webhooks">Amazon SQS</DocLink></li>
                    <li><DocLink to="/dispatch/targets/webhooks">Amazon SNS</DocLink></li>
                </ul>

                <h2>Usage example</h2>
                <p>
                    The code listings below show an example message being scheduled and delivered to pre-configured webhook target.
                </p>

                <SchedulingApiExampleCodeBlock />
                <WebhookRequestExampleCodeBlock />


                <h1><a id="use-cases">Use Cases</a></h1>

                <h2>User onboarding</h2>
                <p>
                    You could use Dispatch to schedule an application message to be delivered a certain amount of time
                    after a user signs up for your application. Upon receiving this message your application could
                    trigger
                    an email to be sent to the user.
                </p>

                <h2>Calendar event reminders</h2>
                <p>
                    It can be important to remind calendar users ahead of their events so that they don't arrive late.

                    You can accomplish this easily be scheduling a message using Dispatch whenever a user RSVP's to an
                    event.

                    When your application receives the message it checks the reminder status and sends a push
                    notification if it is still relevant.
                </p>

                <h2>Abort long-running processes</h2>
                <p>
                    You might be integrating with another service or API that takes a very long time to finish an
                    asynchronous operation, perhaps booking some real physical resource like a hotel room or rental car.
                    Sometimes the service might not respond at all and you need the process to abort.
                </p>
                <p>
                    One approach might be the store the state of each process and continually poll their status. A
                    better approach is to schedule a timeout message using Dispatch and cancel the process upon
                    delivery.
                </p>

                <h1><a id="pricing">Pricing</a></h1>
                <p>
                    Dispatch is priced per each scheduled message. <strong>One message</strong> costs <strong>
                    $0.0001</strong> USD, or <strong>$1</strong> USD for <strong>10,000</strong> messages.
                </p>
                <p>
                    There is a permanent free tier that discounts the first <strong>1000</strong> messages each month.
                </p>
                <h2>Pricing examples</h2>
                <ul>
                    <li>
                        <b>1</b> message per minute on average each month =
                        <br/><span style={{ fontFamily: "monospace" }}>&nbsp;&nbsp;</span><b>60</b> * <b>24</b> * <b>31</b> = <b>44,640</b> messages (31 day month)
                        <br/><span style={{ fontFamily: "monospace" }}>-&nbsp;</span><b>1000</b> (free tier) = <b>43,640</b>
                        <br/><span style={{ fontFamily: "monospace" }}>*&nbsp;</span><b>$0.0001</b> = <b>$0.64</b> USD/month
                        <br/><span style={{ fontFamily: "monospace" }}>=&nbsp;</span><b>$4.36</b> USD/month
                    </li>

                    <li>
                        <b>10</b> messages per hour on average each month =
                        <br/><span style={{ fontFamily: "monospace" }}>&nbsp;&nbsp;</span><b>10</b> * <b>24</b> * <b>31</b> = <b>7,440</b> messages (31 day month)
                        <br/><span style={{ fontFamily: "monospace" }}>-&nbsp;</span><b>1000</b> (free tier) = <b>6,440</b>
                        <br/><span style={{ fontFamily: "monospace" }}>*&nbsp;</span><b>$0.0001</b> = <b>$0.64</b> USD/month
                        <br/><span style={{ fontFamily: "monospace" }}>=&nbsp;</span><b>$0.64</b> USD/month
                    </li>
                </ul>


                <h1><a id="documentation">Documentation</a></h1>

                <h2>Service documentation</h2>
                <ul>
                    <li><DocLink to="/dispatch">Documentation home</DocLink></li>
                    <li><DocLink to="/dispatch/guides">Guides</DocLink></li>
                    <li><DocLink to="/dispatch/examples/api">API Examples</DocLink></li>
                </ul>

                <h2>Reference documentation</h2>
                <ul>
                    <li><DocLink to="/dispatch/reference/api">HTTP API Reference</DocLink></li>
                </ul>
            </PageSection>
        </Page>
    )
}

function SchedulingApiExampleCodeBlock() {
    const accessTokenDescription = "An OAuth 2 access token gotten from our authentication service. Read more about authentication at ..."
    const targetIdDescription = "The ID of the target the message will be delivered to."
    const deliveryTimeDescription = "An ISO 8601 compatible date and time string."
    const payloadDescription = "The payload may be any JSON embeddable string"

    return (
        <Code description="Using cURL to schedule a message">
            <Line>$ curl -X POST https://api.dispatch.molnx.com/messages \ </Line>
            <Line>  -H "Content-Type: application/json" \ </Line>
            <Line>  -H "Authorization: Bearer <Var description={accessTokenDescription}>$ACCESS_TOKEN</Var>" \ </Line>
            <Line>  -d '</Line>
            <Line>{"{"}</Line>
            <Line>  "targetId": "<Var description={targetIdDescription}>DI-T-aabbcc1122</Var>",</Line>
            <Line>  "deliveryTime": "<Var description={deliveryTimeDescription}>2020-07-01T12:34:00Z</Var>",</Line>
            <Line highlight>  "payload": "<Var description={payloadDescription}>Hello, World!</Var>"</Line>
            <Line>{"}"}</Line>
            <Line>'</Line>
        </Code>
    )
}


function WebhookRequestExampleCodeBlock() {
    const endpointDescription = "The URL path as configured in the target."
    const messageIdDescription = "The ID of the message being delivered."
    const targetIdDescription = "The ID of the target being delivered to."
    const originalDeliveryTimeDescription = "The delivery time as specified in the scheduling request. An ISO 8601 date and time string."
    const deliveryTimeDescription = "The actual time when the delivery was made. An ISO 8601 date and time string."
    const payloadDescription = "The payload as specified in the scheduling request"

    return (
        <Code description="An HTTP request being made to a Webhook">
            <Line>POST <Var description={endpointDescription}>/my-target-endpoint</Var></Line>
            <Line>Content-Type: application/json</Line>
            <Line>{" "}</Line>
            <Line>{"{"}</Line>
            <Line>  "messageId": "<Var description={messageIdDescription}>DI-M-11111111-2222-3333-4444-555555555555</Var>",</Line>
            <Line>  "targetId": "<Var description={targetIdDescription}>DI-T-aabbcc1122</Var>",</Line>
            <Line>  "originalDeliveryTime": "<Var description={originalDeliveryTimeDescription}>2020-07-01T12:34:00Z</Var>",</Line>
            <Line>  "deliveryTime": "<Var description={deliveryTimeDescription}>2020-07-01T12:34:56Z</Var>",</Line>
            <Line highlight>  "payload": "<Var description={payloadDescription}>Hello, World!</Var>"</Line>
            <Line>{"}"}</Line>
        </Code>
    )
}
